
const dev = {
  API: {
    URL: 'https://dev.ronquillo.tech:9090',
    USER_NAME: 'Portal-Clientes',
    PASSWORD: 'z9f2ai0LHRkj5cHaMxaOc5iE6lWH6Ed26XmdYOwwLPv5PaqNNjTPIrSEc8Zeh4le',
  },
  LINKS: {
    QUOTER: "https://dev.financierapoint.com.mx/cotizador",
    POINT: "https://dev.financierapoint.com.mx",
    PRIVACY_NOTICE: "https://dev.financierapoint.com.mx/aviso-privacidad",
  }
};

const prod = {
  API: {
    URL: 'https://api.financierapoint.com.mx',
    USER_NAME: 'Portal-Clientes',
    PASSWORD: '3reAc7L8h9B6kzk2Tpkx6YrEXOCp6g1YHRgFVUvkLlJV8IB2soxBYbY9V5IFZUei',
  },
  LINKS: {
    QUOTER: "https://financierapoint.com.mx/cotizador",
    POINT: "https://financierapoint.com.mx",
    PRIVACY_NOTICE: "https://financierapoint.com.mx/aviso-privacidad",
  }
};

// TODO: No guardar credenciales en el front
export const Config = process.env.REACT_APP_ENVIRONMENT === "prod" ? prod : dev;
